<template>
    <div class="surface-card p-6 border-1 surface-border">
        <Dialog
            :header="$t('Edit Survey')"
            :visible.sync="editedSurvey.hasOwnProperty('@id')"
            @hide="editedSurvey = {}"
            :closable="false"
        >
            <SelectButton
                style="min-width: 400px;"
                class="mb-4"
                v-model="editedSurvey.status"
                optionValue="id"
                optionLabel="label"
                :options="statusOptions"
            />

            <div class="text-center">
                <Button
                    class="p-button p-button-secondary mr-4"
                    :label="$t('Abort')"
                    @click="editedSurvey = {}"
                />
                <Button
                    class="p-button"
                    :label="$t('Update')"
                    @click="updateSurveyStatus(editedSurvey)"
                />
            </div>
        </Dialog>

        <data-table-view
            ref="dataTable"
            endpoint="surveys"
            :headline="$t('Surveys')"
            :pre-set-params="{order: {createdAt: 'DESC'}}"
        >
            <template slot="actions">
                <router-link
                    to="/surveys/create"
                    v-if="$store.getters.currentUser.permissions.hasOwnProperty('admin') && $store.getters.currentUser.permissions.admin === true"
                >
                    <Button
                        :label="$t('Create new survey')"
                        class="p-button-primary p-button-sm"
                    />
                </router-link>
            </template>
            <Column
                field="createdAt"
                :header="$t('Created at')"
                sortable
            >
                <template #body="slotProps">
                    {{ slotProps.data.createdAt|formatDate('LLL') }}
                </template>
            </Column>
            <Column
                field="company.name"
                :header="$t('Company')"
                sortable
            />
            <Column
                field="unit"
                :header="$t('Department')"
                sortable
            />
            <Column
                field="location"
                :header="$t('Location')"
            />

            <Column field="status" header="Status">
                <template #body="slotProps">
                    {{ slotProps.data.status|dataTrans('model.survey.status') }}
                </template>
            </Column>

            <Column header="" :styles="{'text-align': 'right'}">
                <template #body="slotProps">
                    <router-link
                        v-if="!$store.getters.currentUser.permissions.hasOwnProperty('admin') || $store.getters.currentUser.permissions.admin !== true"
                        :to="'/surveys/' + slotProps.data.id + '/structure'"
                    >
                        <i
                            class="pi"
                            :class="{
                                'pi-search': slotProps.data.status === 'finished',
                                'pi-pencil': slotProps.data.status === 'draft',
                            }">
                        </i>
                    </router-link>
                    <template v-else>
                        <router-link
                            class="mr-3"
                            :to="'/surveys/' + slotProps.data.id + '/structure'"
                        >
                            <i class="pi pi-search"></i>
                        </router-link>
                        <a @click="editedSurvey = Object.assign({}, slotProps.data)">
                            <i class="pi pi-pencil"></i>
                        </a>
                    </template>
                </template>
            </Column>
        </data-table-view>
    </div>
</template>

<script>
    import Button from 'primevue/button';
    import Column from 'primevue/column';
    import DataTableView from "@/components/DataTableView";
    import InputText from "primevue/inputtext/InputText";
    import TriStateCheckbox from "primevue/tristatecheckbox";
    import Dialog from 'primevue/dialog';
    import Dropdown from "primevue/dropdown";
    import SelectButton from "primevue/selectbutton";

    export default {
        components: {
            Dialog,
            InputText,
            Button,
            DataTableView,
            Column,
            TriStateCheckbox,
            Dropdown,
            SelectButton
        },
        computed: {
            statusOptions: function() {
                let options = [];
                Object.keys(this.$store.getters.settings.model.survey.status).forEach((item) => {
                    options.push({
                        id: item,
                        label: this.$store.getters.settings.model.survey.status[item]
                    })
                });

                return options;
            }
        },
        methods: {
            updateSurveyStatus(editedEntity) {
                let entityParam = {
                    id: editedEntity.id,
                    status: editedEntity.status,
                    '@id': editedEntity['@id']
                }
                console.log(entityParam);
                this.$sendToApi('surveys', entityParam, (entity, status, data) => {
                    this.$refs.dataTable.reload();
                    this.editedSurvey = {};
                }, 'patch');
            }
        },
        data() {
            return {
                editedSurvey: {},
            }
        }
    }
</script>
